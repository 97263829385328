import {setCacheExpirationDate} from "utils/LocalStorage";
import Event from "./Event";
import Period from "./Period";

class User {
    id;
    isActive;
    isVisible;
    cp;
    cpManager;
    cpPayManager;
    rtt;
    rttManager;
    rttPayManager;
    rttRemainder;
    rest;
    remainder;
    remainderManager;
    remainderPayManager;
    cp15;
    cp15Manager;
    cp15PayManager;
    isManager;
    matricule;
    username;
    firstname;
    lastname;
    roles = [];
    manager;
    loaded = false;
    seniorityAt;
    startAt;
    endAt;
    type;
    userForAbsenceListFetchedAt;
    userForCRAListFetchedAt;
    optedOutEuro2020PopUp;
    displayRemainderCounter;
    displayQuota;
    quota;
    displayWorkedDays;
    workedDays;
    passwordExpirationDate;
    currentPeriod;
    lastAbsences;
    cacheExpireAt;
    absences;
    modality;
    personType;
    remoteDays = [];
    availableMatricules = [];

    constructor(json, isMe) {
        this.update(json, isMe);
    }

    update(json, isMe) {
        if (json) {
            this.id = json.id;
            this.isActive = json.is_active;
            this.isVisible = json.is_visible;
            this.cp = json.cp;
            this.cpManager = json.cp_manager;
            this.cpPayManager = json.cp_pay_manager;
            this.rtt = json.rtt;
            this.rttManager = json.rtt_manager;
            this.rttPayManager = json.rtt_pay_manager;
            this.rttRemainder = json.rtt_remainder;
            this.rest = json.rest;
            this.cp15 = json.cp15;
            this.cp15Manager = json.cp15_manager;
            this.cp15PayManager = json.cp15_pay_manager;
            this.remainder = json.remainder;
            this.remainderManager = json.remainder_manager;
            this.remainderPayManager = json.remainder_pay_manager;
            this.beforeJune = json.before_june;
            this.isManager = json.is_manager;
            this.matricule = json.matricule;
            this.username = json.username;
            this.firstname = json.firstname;
            this.lastname = json.lastname;
            this.roles = json.roles;
            this.manager = json.manager;
            this.loaded = true;
            this.seniorityAt = json.seniority_at;
            this.startAt = json.start_at;
            this.endAt = json.end_at;
            this.lastEndAt = json.last_end_at;
            this.type = json.type;
            this.userForAbsenceListFetchedAt =
                json.user_for_absence_list_fetched_at;
            this.userForCRAListFetchedAt = json.user_for_cra_list_fetched_at;
            this.optedOutEuro2020PopUp = json.opted_out_euro2020_pop_up;
            this.displayRemainderCounter = json.display_remainder_counter;
            this.displayQuota = json.display_quota;
            this.quota = json.quota;
            this.displayWorkedDays = json.display_worked_days;
            this.workedDays = json.worked_days;
            this.passwordExpirationDate = json.password_expire_at;
            this.currentPeriod = json.current_period;
            this.lastAbsences = [];
            this.absences = [];
            this.modality = json.modality;
            this.personType = json.person_type;
            this.availableMatricules = json.available_matricules;

            if (typeof json.absences != "undefined") {
                json.absences.map((event) => {
                    this.absences.push(new Event(event));
                });
            }

            if (typeof json.remote_days != "undefined") {
                this.remoteDays = json.remote_days
            }

            if (isMe) {
                if (
                    !localStorage.getItem("userForAbsenceListFetchedAt") ||
                    parseInt(
                        localStorage.getItem("userForAbsenceListFetchedAt")
                    ) !== this.userForAbsenceListFetchedAt
                ) {
                    localStorage.setItem(
                        "userForAbsenceListFetchedAt",
                        this.userForAbsenceListFetchedAt
                    );
                    localStorage.removeItem("userForAbsenceList");
                }

                if (
                    !localStorage.getItem("userForCRAListFetchedAt") ||
                    parseInt(
                        localStorage.getItem("userForCRAListFetchedAt")
                    ) !== this.userForCRAListFetchedAt
                ) {
                    localStorage.setItem(
                        "userForCRAListFetchedAt",
                        this.userForCRAListFetchedAt
                    );
                    localStorage.removeItem("userForCRAList");
                }

                this.cacheExpireAt = new Date(json.cache_expire_at);
                setCacheExpirationDate(this.cacheExpireAt);

                json.last_absences.map((event) => {
                    this.lastAbsences.push(new Event(event));
                });
            }
        }
    }

    hasRole(role) {
        return this.roles.indexOf(role) >= 0;
    }

    canRunAs() {
        return this.hasRole("ROLE_ALLOWED_TO_SWITCH");
    }

    get identity() {
        return [this.firstname, this.lastname].join(" ");
    }

    get label() {
        return [this.firstname, this.lastname].join(" ");
    }

    isContractor() {
        if (this.type === "contractor") {
            return true;
        }

        return false;
    }

    isInternal() {
        if (this.type === "internal") {
            return true;
        }

        return false;
    }

    isConsultant() {
        if (this.type === "consultant") {
            return true;
        }

        return false;
    }
}

export default User;
