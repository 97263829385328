import React, {Fragment, useEffect, useState} from "react";
import {Redirect, Route, Switch} from "react-router";
import "moment/locale/fr";
import moment from "moment";
import Header from "components/common/Header/Header";
import TimesheetContainer from "components/TimesheetContainer/TimesheetContainer";
import ContactContainer from "components/ContactContainer/ContactContainer";
import NewsContainer from "components/NewsContainer/NewsContainer";
import GDPRContainer from "components/GDPRContainer/GDPRContainer";
import RunAsContainer from "components/RunAsContainer/RunAsContainer";
import MyAccountContainer from "components/MyAccountContainer/MyAccountContainer";
import Error from "components/common/Error";
import {getContactRoutes} from "config/Routes";
import TutorialContainer from "./TutorialContainer/TutorialContainer";
import DocumentContainer from "./DocumentContainer/DocumentContainer";
import ParametersContainer from "./ParametersContainer/ParametersContainer"
import "moment/locale/fr";
import {useDispatch, useSelector} from "react-redux";
import {AnimatePresence} from "framer-motion/dist/framer-motion";
import {useLocation, useHistory} from "react-router-dom";
import PageTransition from "./common/PageTransition";
import {setFirstLoad} from "../actions/Common";
import {changeOverlayContent} from "events/OverlayEvents";
import HomePage from "./pages/HomePage/HomePage";
import TimesheetPage from "./pages/TimesheetPage/TimesheetPage";
import DocumentsPage from "./DocumentContainer/DocumentsPage";
// import GreenlyLink from "./common/GreenlyLink";
// import GreenlyPopUp from "./HomepageContainer/GreenlyPopUp/GreenlyPopUp";

const FrontendRoutes = (props) => {
    const {me} = props;
    const [popupVisible, setPopupVisible] = useState(!localStorage.getItem("greenly2025Hidden"));
    const isMobile = useSelector((state) => state.common.isMobile)
    const location = useLocation();

    // const today = moment().format("YYYY-MM-DD");
    // End 04/03/2025
    // const endGreenlyPopup = moment().set('year', 2025).set('month', 3).set('date', 8).format('YYYY-MM-DD')

    // useEffect(() => {
    //     if (me.loaded && popupVisible && me.type !== "contractor" && today < endGreenlyPopup) {
    //         changeOverlayContent(
    //             <GreenlyPopUp setPopupVisible={setPopupVisible}/>
    //         );
    //     }
    // })

    return (
        <Fragment>
            {!isMobile && <Header/>}
            {/*{me.type !== "contractor" && !isMobile  && today < endGreenlyPopup && <GreenlyLink setPopupVisible={setPopupVisible}/>}*/}
            <Switch location={location} key={location.pathname}>
                <Route exact path="/">
                    <HomePage/>
                </Route>
                <Route path="/timesheet/:month?/:year?">
                    <PageTransition backgroundColor="#007188" text="Mes activités" icon={"fa-calendar-edit"}>
                        <TimesheetPage/>
                    </PageTransition>
                </Route>

                <Route
                    strict
                    exact
                    path={getContactRoutes(props.me)}
                >
                    <PageTransition backgroundColor="#a51160" text="Mes Contacts" icon={"fa-user-friends"}>
                        <ContactContainer/>
                    </PageTransition>
                </Route>

                <Route exact path="/account">
                    <Redirect to="/parameters#account"/>
                </Route>

                <Route exact path="/parameters" component={() => <ParametersContainer personType={me.type}/>}>
                    <PageTransition backgroundColor="#f2f2f2" textColor="#17313a" text="Mes paramètres" icon={"fa-cog"}>
                        <ParametersContainer personType={me.type}/>
                    </PageTransition>
                </Route>

                <Route exact path="/actus/:postId?">
                    <PageTransition backgroundColor="#007188" text="Mes Actualités" icon={"fa-newspaper"}>
                        <NewsContainer/>
                    </PageTransition>
                </Route>
                <Route exact path="/rgpd" component={() => <GDPRContainer/>}/>
                <Route
                    exact
                    path="/tutorial"
                    component={() => <TutorialContainer/>}
                >
                    <PageTransition backgroundColor="#17313a" text="Mes Tutos" icon={"fa-file"}>
                        <TutorialContainer/>
                    </PageTransition>
                </Route>

                {me.id && me.type !== "contractor" && (
                    <Route
                        exact
                        path={[
                            "/documents",
                            "/documents/:type",
                            "/documents/:type/:id",
                        ]}
                    >
                        <DocumentsPage/>
                    </Route>
                )}

                {me.hasRole("ROLE_ALLOWED_TO_SWITCH") && (
                    <Route
                        exact
                        path="/runas"
                        component={() => <RunAsContainer/>}
                    />
                )}

                <Route exact path="/login">
                    <Redirect to="/"/>
                </Route>

                {/*<Route*/}
                {/*    path="/error"*/}
                {/*    component={() => (*/}
                {/*        <Error label="Une erreur est survenue, veuillez recharger la page."/>*/}
                {/*    )}*/}
                {/*/>*/}

                {/*<Route*/}
                {/*    path="/maintenance"*/}
                {/*    component={() => (*/}
                {/*        <Error*/}
                {/*            label="Une maintenance est en cours, veuillez réessayer plus tard."*/}
                {/*            btnLabel="Recharger"*/}
                {/*            reloadTimeout={300000}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*/>*/}

                <Route
                    path="*"
                    component={() => (
                        <Error
                            code={404}
                            label="La page demandée n'existe pas."
                        />
                    )}
                />
            </Switch>
        </Fragment>
    );
};

export default FrontendRoutes;
