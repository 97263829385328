import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

import {changeOverlayContent} from "events/OverlayEvents";
import ActivityValidationForm from "components/Backend/CRAContainer/ActivityValidationForm";
import CRAHistoryActions from "components/Backend/CRAContainer/CRAHistoryActions";
import CommentBox from "components/Backend/CRAContainer/CommentBox";
import ActivityEditType from "components/Backend/CRAContainer/ActivityEditType";
import DownloadCRA from "components/TimesheetContainer/Calendar/DownloadCRA";

import * as TimesheetActions from "actions/Timesheet";

import "./ActivityActionsBlock.scss";

const ActivityActionsBlock = (props) => {
    const {me, month, user, manager, period, loadList} = props;
    const [downloadUrl, setDownloadUrl] = useState("");
    const dispatch = useDispatch();

    const canViewTimesheet = () => {
        if (me.hasRole("ROLE_ACTIVITY_SHOW")) {
            return true;
        }
        return false;
    };

    const canValidateLevel1 = () => {
        if (
            me.hasRole("ROLE_ACTIVITY_ACCEPT_FIRST_LEVEL") &&
            month.absenceValidated1 &&
            !month.locked &&
            month.state === "submitted" &&
            me.availableMatricules.includes(user.matricule)
            // ((user.manager === me.matricule &&
            //         me.hasRole("ROLE_ACTIVITY_ACCEPT_SECOND_LEVEL")) ||
            //     !me.hasRole("ROLE_ACTIVITY_ACCEPT_SECOND_LEVEL"))
        ) {
            return true;
        }
        return false;
    };

    const canValidateLevel2 = () => {
        if (
            me.hasRole("ROLE_ACTIVITY_ACCEPT_SECOND_LEVEL") &&
            month.absenceValidated2 &&
            !month.locked &&
            month.state === "validated1"
        ) {
            return true;
        }
        return false;
    };

    const canEdit = () => {
        if (
            me.hasRole("ROLE_ACTIVITY_EDIT_TYPE") &&
            month.submitted &&
            !month.locked &&
            (month.state === "submitted" || month.state === "validated1")
        ) {
            return true;
        }

        return false;
    };

    const canBackward = () => {
        if (
            me.hasRole("ROLE_ACTIVITY_BACKWARD_FIRST_LEVEL") &&
            !month.locked &&
            month.state === "submitted"
        ) {
            return true;
        } else if (
            me.hasRole("ROLE_ACTIVITY_BACKWARD_SECOND_LEVEL") &&
            (month.state === "submitted" ||
                month.state === "validated1" ||
                month.state === "validated2") &&
            !month.locked
        ) {
            return true;
        }

        return false;
    };

    const canReadComments = () => {
        if (me.hasRole("ROLE_ACTIVITY_COMMENT")) {
            return true;
        }

        return false;
    };

    const canViewHistory = () => {
        if (me.hasRole("ROLE_ACTIVITY_ACTION_HISTORY")) {
            return true;
        }

        return false;
    };

    const getValidatedState = () => {
        if (me.hasRole("ROLE_ACTIVITY_ACCEPT_FIRST_LEVEL")) {
            return "validated1";
        } else if (me.hasRole("ROLE_ACTIVITY_ACCEPT_SECOND_LEVEL")) {
            return "validated2";
        }
    };

    const handleDownload = (fileId) => {
        dispatch(TimesheetActions.downloadCRA(fileId)).then((response) => {
            window.open(response.response.result, "_blank");
        });
    };

    const displayEventCodesChoicesForDownload = () => {
        changeOverlayContent(
            <DownloadCRA monthEntity={month} targetUser={user} type="view"/>
        );
    };

    return (
        <div className="activity-actions-block">
            {canViewTimesheet() ? (
                <Link
                    className="fal fa-calendar cra-display action-btn"
                    title="Voir le CRA"
                    to={`/backend/cra/${month.id}/${month.month}/${month.year}/${user.matricule}`}
                    target="_blank"
                />
            ) : (
                <span className="action-btn">&nbsp;</span>
            )}

            {canViewTimesheet() ? (
                <i
                    className="fal fa-file-download action-btn"
                    title="Télécharger le CRA"
                    onClick={displayEventCodesChoicesForDownload}
                ></i>
            ) : (
                <span className="action-btn">&nbsp;</span>
            )}

            {canValidateLevel1() ? (
                <i
                    title="Valider le CRA"
                    className="far fa-check-circle action-btn"
                    onClick={() =>
                        changeOverlayContent(
                            <ActivityValidationForm
                                action="validate"
                                user={user}
                                manager={manager}
                                month={month}
                                period={period}
                                validatedState={"validated1"}
                                loadList={loadList}
                            />
                        )
                    }
                ></i>
            ) : (me.hasRole("ROLE_ACTIVITY_ACCEPT_FIRST_LEVEL") ||
                me.hasRole("ROLE_ACTIVITY_ACCEPT_SECOND_LEVEL")) &&
            (month.state === "validated1" || month.state === "validated2") ? (
                <i
                    className="far fa-check-circle green-btn"
                    title="CRA validé"
                ></i>
            ) : (
                <span className="action-btn">&nbsp;</span>
            )}

            {canValidateLevel2() ? (
                <i
                    title="Valider le CRA"
                    className="far fa-check-circle action-btn"
                    onClick={() =>
                        changeOverlayContent(
                            <ActivityValidationForm
                                action="validate"
                                user={user}
                                manager={manager}
                                month={month}
                                period={period}
                                validatedState={"validated2"}
                                loadList={loadList}
                            />
                        )
                    }
                ></i>
            ) : me.hasRole("ROLE_ACTIVITY_ACCEPT_SECOND_LEVEL") &&
            month.state === "validated2" ? (
                <i
                    className="far fa-check-circle green-btn"
                    title="CRA validé"
                ></i>
            ) : (
                <span className="action-btn">&nbsp;</span>
            )}

            {canEdit() ? (
                <i
                    title="Modifier le libellé d'une activité"
                    className="far fa-edit action-btn"
                    onClick={() =>
                        changeOverlayContent(
                            <ActivityEditType
                                user={user}
                                manager={manager}
                                month={month}
                                period={period}
                                // event={event}
                                // eventCodeDescription={eventCodeDescription}
                                validatedState={"validated1"}
                                loadList={loadList}
                            />
                        )
                    }
                ></i>
            ) : (
                <span className="action-btn">&nbsp;</span>
            )}

            {canBackward() ? (
                <i
                    className="far fa-reply action-btn"
                    title="Redonner la main au collaborateur"
                    onClick={() =>
                        changeOverlayContent(
                            <ActivityValidationForm
                                action="backward"
                                user={user}
                                manager={manager}
                                month={month}
                                period={period}
                                // event={event}
                                // eventCodeDescription={eventCodeDescription}
                                validatedState={"validated1"}
                                loadList={loadList}
                            />
                        )
                    }
                ></i>
            ) : (
                <span className="action-btn">&nbsp;</span>
            )}

            {canReadComments() && month.commentCount > 0 ? (
                <span
                    className="fa-stack fa-1x comment-count-container"
                    onClick={() =>
                        changeOverlayContent(
                            <CommentBox
                                month={month}
                                user={user}
                                manager={manager}
                            />
                        )
                    }
                >
                    <i
                        className="fas fa-stack-2x fa-comment-alt"
                        title="Voir les commentaires"
                    ></i>
                    <span className="fa fa-stack-1x"
                          title="Voir les commentaires">
                        <span className="comment-count">
                            {month.commentCount}
                        </span>
                    </span>
                </span>
            ) : (
                <span className="empty-comment-count-container action-btn">
                    &nbsp;
                </span>
            )}

            {canViewHistory() && (
                <i
                    className="far fa-info-circle action-btn"
                    title="Voir l'historique des actions sur le CRA"
                    onClick={() =>
                        changeOverlayContent(
                            <CRAHistoryActions
                                month={month}
                                user={user}
                                manager={manager}
                            />
                        )
                    }
                ></i>
            )}

            {canViewTimesheet() &&
                Object.values(month.files).map((file, key) => (
                    <i
                        key={key}
                        className="action-btn download-specific-cra far fa-file-alt"
                        title={file.name}
                        onClick={() => handleDownload(file.id)}
                    ></i>
                ))}

            <div className="cra-counters">
                {user.displayWorkedDays &&
                    <div className="worked-days-counter" title="Projection de jours travaillés au 31/12"><i
                        className="fal fa-calendar-exclamation"></i> {user.workedDays} J</div>}
                {user.displayQuota && <div className="quota-counter" title="Projection du contingent au 31/12"><i
                    className="fal fa-calendar-exclamation"></i> {user.quota} h</div>}
            </div>
        </div>
    );
};

export default ActivityActionsBlock;
