import React, {useEffect, useState, Fragment} from "react";
import "./HomepageContainer.scss";
import {useSelector, useDispatch} from "react-redux";
import "moment/locale/fr";
import moment from "moment";
import Disclaimer from "components/common/Disclaimer";
import {changeOverlayContent} from "events/OverlayEvents";
import {motion} from 'framer-motion/dist/framer-motion'
import * as NewsActions from "actions/News";
import * as UserActions from "actions/User";
import {setAppLoading} from "actions/Common";
import HomepagePost from "components/HomepageContainer/HomepagePost";
import HomepageNews from "./HomepageNews";
import SecurityPopUp from "./SecurityPopUp/SecurityPopUp";
import applications from "../../config/Home.json"
import {HomeAppContainer} from "../common/HomeAppContainer";
// import GreenlyPopUp from "./GreenlyPopUp/GreenlyPopUp";

const HomepageContainer = () => {
    const dispatch = useDispatch();
    const [scale, setScale] = useState(1)

    // const [popupVisible, setPopupVisible] = useState(!localStorage.getItem("greenly2025Hidden"));
    // const today = moment().format("YYYY-MM-DD");
    // // End 04/03/2025
    // const endGreenlyPopup = moment().set('year', 2025).set('month', 3).set('date', 8).format('YYYY-MM-DD')

    const me = useSelector((state) => state.user.me);
    const internalNews = useSelector((state) => state.news.entities.internalNews);

    const loadSpreadTracker = () => {
        window.domLoadEventFired = !0;
        let a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.charset = "UTF-8";
        a.id = "spread-tracker";
        a.src = "//static-sb.com/js/sb-tracker.js";
        document.body.appendChild(a);
    };

    useEffect(() => {
        if (!me.loaded) {
            dispatch(setAppLoading(true));
            dispatch(UserActions.fetchCurrentUser());
        }
        dispatch(NewsActions.fetchHighlight());
        dispatch(NewsActions.fetchInternalNews());
    }, []);

    useEffect(() => {
        // Méthode de chargement du tracker
        if (typeof me !== "undefined") {
            if (me.type === "consultant" || me.type === "internal") {
                loadSpreadTracker();
            }
        }

        // if (me.loaded && popupVisible && me.type !== "contractor" && today < endGreenlyPopup) {
        //     changeOverlayContent(
        //         <GreenlyPopUp setPopupVisible={setPopupVisible}/>
        //     );
        // }
    }, [me]);

    useEffect(() => {
        const handleResize = () => {
            setScale(Math.min(window.innerWidth / 1200 * 0.95, 1))
        }
        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const onlyContractor = me.type === "contractor"

    const findApps = (list) => {
        return applications.filter(app => list.includes(app.name) && (onlyContractor ? app.contractor === true : true)).map(app => app.name)
    }
    const mainApps = findApps(['timesheet', 'contacts', 'expensya', 'news', 'documents', 'social', 'tutorial'])

    return (
        me.loaded && (
            <motion.div id="page-container"
                        className={"home-page-desktop" + (me.type === "contractor" ? " home-page-contractor" : "")}
                        initial={{y: 1000}}
                        animate={{y: 0}}
                        transition={{duration: 1, ease: [0.22, 1, 0.36, 1]}}
            >
                <div className="homepage-container">

                    <div className="container">
                        <div className="home-apps">
                            {me.type !== "contractor" &&
                                <div className="home-upper-container" style={{transform: "scale(" + scale + ")"}}>
                                    <HomepageNews internalNews={internalNews}/>
                                    <HomepagePost/>
                                </div>}

                            <div
                                className={"home-lower-container" + (me.type === "contractor" ? " lower-container-no-width" : "")}>
                                <HomeAppContainer isMobile={false} applications={mainApps}/>
                            </div>
                            <Disclaimer isContractor={me.type === "contractor"}/>
                        </div>
                    </div>
                </div>
            </motion.div>
        )
    );
};

export default HomepageContainer;
